import $ from "jquery";
import "what-input";

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
// require("./lib/jquery.migrate");
require("foundation-sites");
require("waypoints/lib/jquery.waypoints");
// require("flatpickr/dist/flatpickr");
require("./lib/jquery.magnific-popup");
require("./lib/imagesloaded.min");
require("./lib/ion.rangeSlider");
const flatpickr = require("./lib/flatpickr");

var Swiper = require("./lib/swiper");
const queryString = require("query-string");

// require("./lib/jquery.sticky-kit");

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

$(document)
  .foundation()
  .ready(function () {
    var tabletWidth = 1080;
    var mobileWidth = 991;
    var headerHeight = 92;
    $("#search-form").submit(function (e) {
      if (!$(this).hasClass("active")) {
        e.preventDefault();
        $(this).addClass("active");
        $(".header-banner-right").addClass("active");
        $(this).find("input").focus();
      }
    });

    $("#search-form input").blur(function () {
      if ($(this).val() == "") {
        $("#search-form").removeClass("active");
        $(".header-banner-right").removeClass("active");
      }
    });

    $(window).scroll(function (event) {
      scrollCheck();
    });

    $(window).resize(function () {
      Waypoint.refreshAll();
      inclusionHotspot();
    });

    inclusionHotspot();
    scrollCheck();

    function scrollCheck() {
      if ($(window).scrollTop() > 140) {
        $("#header, .header-navigation, #header-menu").addClass("scrolled");
        if ($(".sticker-ad").is(":visible")) {
          var stickerHeight = $(".sticker-ad").height();
          var adminBarHeight = $("#wpadminbar").length
            ? $("#wpadminbar").height()
            : 0;
          var headerHeight = 92;
          if ($(window).width() <= 1080) {
            $("#header-menu").css({
              top: stickerHeight + "px",
            });
          } else {
            $("#header-menu").css({
              top: stickerHeight + adminBarHeight + headerHeight + "px",
            });
          }
        }
      } else {
        $("#header, .header-navigation, #header-menu").removeClass("scrolled");
        if ($(".sticker-ad").is(":visible")) {
          var stickerHeight = $(".sticker-ad").height();
          var adminBarHeight = $("#wpadminbar").length
            ? $("#wpadminbar").height()
            : 0;
          var headerHeight = 140;
          if ($(window).width() <= 1080) {
            $("#header-menu").css({
              top: stickerHeight + "px",
            });
          } else {
            $("#header-menu").css({
              top: stickerHeight + adminBarHeight + headerHeight + "px",
            });
          }
        }
      }
    }

    function inclusionHotspot() {
      $(".inclusion").each(function () {
        var that = this;
        $(that)
          .find("img")
          .imagesLoaded()
          .done(function () {
            $(that)
              .find(".hotspot")
              .each(function () {
                positionHotSpots($(this));
              });
          });
      });
    }

    $(".floor-plan-facades .swiper-container").each(function () {
      var swiperFacade = new Swiper($(this)[0], {
        speed: 600,
      });
      $(this)
        .closest(".floor-plan-facades-container")
        .find(".btn-next")
        .click(function () {
          swiperFacade.slideNext();
        });
      $(this)
        .closest(".floor-plan-facades-container")
        .find(".btn-prev")
        .click(function () {
          swiperFacade.slidePrev();
        });
    });

    if ($(".hero-slider").length) {
      var heroSlider = new Swiper(".hero-slider", {
        autoplay: {
          delay: 5000,
        },
        speed: 600,
        effect: "fade",
        loop: true,
        allowTouchMove: false,
        on: {
          init: function () {
            sliderReady();
          },
        },
      });

      heroSlider.on("slideChangeTransitionEnd", function () {
        $(".hero-slider .swiper-slide")
          .not($(".hero-slider .swiper-slide-active"))
          .removeClass("ready");
        sliderReady();
      });

      function sliderReady() {
        $(".hero-slider .swiper-slide-active").addClass("ready");
        if (heroSlider) {
          $(".swiper-menu a").removeClass("active");
          $(".swiper-menu a").each(function () {
            if ($(this).data("index") == heroSlider.realIndex) {
              $(this).addClass("active");
              var offsetLeft = $(this).offset().left;
              $(".swiper-menu").animate(
                {
                  scrollLeft: offsetLeft,
                },
                "fast"
              );
            }
          });
        }
      }

      $(".swiper-menu a").click(function () {
        if (heroSlider) {
          $(this).blur();
          heroSlider.slideToLoop($(this).data("index"));
        }
      });
    }

    $(function () {
      var boxes = $("[data-scroll-speed]"),
        $window = $(window);
      $window.on("scroll", function () {
        var scrollTop = $window.scrollTop();
        boxes.each(function () {
          var $this = $(this),
            scrollspeed = parseInt($this.data("scroll-speed")),
            val = -scrollTop / scrollspeed;
          val = val * -1;
          $this.css("transform", "translateY(" + val + "px)");
        });
      });
    });

    function getWindowWidth() {
      var windowWidth = 0;
      if (typeof window.innerWidth == "number") {
        windowWidth = window.innerWidth;
      } else {
        if (document.documentElement && document.documentElement.clientWidth) {
          windowWidth = document.documentElement.clientWidth;
        } else {
          if (document.body && document.body.clientWidth) {
            windowWidth = document.body.clientWidth;
          }
        }
      }
      return windowWidth;
    }

    var subMenu = "";
    var subMenuTimeout;

    $("#header .navigation a.header-menu").mouseover(function () {
      if (getWindowWidth() > tabletWidth) {
        clearTimeout(subMenuTimeout);
        subMenu = $(this).data("menu");
        $("#header .navigation a.header-menu").removeClass("hover-active");
        $(this).addClass("hover-active");
        $("#header-menu .header-menu-container").each(function () {
          if ($(this).attr("id") == subMenu) {
            $(this).show();
          } else {
            $(this).hide();
          }
        });
        $("#header-menu").fadeIn("fast");
      }
    });

    $("#header .navigation a.header-menu, #header-menu").mouseleave(
      function () {
        if (getWindowWidth() > tabletWidth) {
          subMenuTimeout = setTimeout(function () {
            $("#header-menu").fadeOut("fast", function () {
              $("#header-menu div.menu").each(function () {
                if ($(this).attr("id") == subMenu) {
                  $(this).hide();
                }
              });
              $("#header .navigation a.header-menu").each(function () {
                if ($(this).data("menu") == subMenu) {
                  $(this).removeClass("hover-active");
                }
              });
            });
          }, 300);
        }
      }
    );

    $("#header-menu").mouseover(function () {
      if (getWindowWidth() > tabletWidth) {
        clearTimeout(subMenuTimeout);
      }
    });

    $("#hamburger").click(function () {
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        $(
          ".header-navigation, .navigation, .main, html, body, .header-menu-container, .menu-content"
        ).removeClass("menu-open");
      } else {
        $(this).addClass("active");
        $(".header-navigation, .navigation, .main, html, body").addClass(
          "menu-open"
        );
      }
    });

    $(".navigation a.header-menu").click(function () {
      if (getWindowWidth() <= tabletWidth) {
        $(".header-menu-container").removeClass("menu-open");
        $("#header-menu")
          .find("#" + $(this).data("menu"))
          .addClass("menu-open");
      }
    });

    $(".menu-sub a").click(function () {
      var target = "#menu-" + $(this).data("menu-sub");
      $(target).addClass("menu-open");
    });

    $(".menu-navigation a.back").click(function () {
      if ($(this).closest(".menu-content-flex-container").length) {
        $(this).closest(".menu-content").removeClass("menu-open");
      } else {
        $(this).closest(".header-menu-container").removeClass("menu-open");
      }
      $(this).blur();
    });

    $(document)
      .imagesLoaded()
      .done(function () {
        $(".animate").waypoint(
          function () {
            if (!$(this.element).hasClass("animate-ready")) {
              $(this.element).addClass("animate-ready");
            }
          },
          { offset: "85%" }
        );
      });

    // contact map
    var contactMap;
    var markers = [];

    function initContactMap() {
      contactMap = new google.maps.Map(document.getElementById("contact-map"), {
        zoom: 16,
        fullscreenControl: false,
        mapTypeControl: false,
        scrollwheel: false,
        controlSize: 22,
        center: { lat: -37.646829, lng: 144.96919 },
      });

      var contactMarker = {
        url: "/assets/img/icon-marker-aston@2x.png",
        scaledSize: new google.maps.Size(66, 85),
      };
      new google.maps.Marker({
        position: { lat: -37.6469396, lng: 144.971607 },
        map: contactMap,
        icon: contactMarker,
      });
    }

    if ($("#contact-map").length > 0) {
      initContactMap();
    }

    // House land map
    var houseLandMap;
    var houseLandMarkers = [];

    function initHouseLandMap($id) {
      var zoomMap = 10;
      var markerScale = 0.7;
      if (getWindowWidth() <= 500) {
        zoomMap = 9;
        markerScale = 0.55;
      }
      houseLandMap = new google.maps.Map(document.getElementById($id), {
        zoom: zoomMap,
        // minZoom: 10,
        fullscreenControl: false,
        scrollwheel: false,
        streetViewControl: false,
        controlSize: 22,
        center: { lat: -37.529818, lng: 144.970579 },
        // styles: [
        //   {
        //     stylers: [
        //       {
        //         saturation: -120
        //       }
        //     ]
        //   }
        // ]
      });

      var innerCircle = new google.maps.Circle({
        strokeWeight: 0,
        fillColor: "#FFAE3D",
        fillOpacity: 0.25,
        map: houseLandMap,
        center: { lat: -37.686279, lng: 144.9489883 },
        radius: 35000,
      });

      var outerCircle = new google.maps.Circle({
        strokeWeight: 0,
        fillColor: "#FFAE3D",
        fillOpacity: 0.2,
        map: houseLandMap,
        center: { lat: -37.686279, lng: 144.9489883 },
        radius: 52000,
      });

      var baseUrl = "/assets/img/map/";
      if ($id == "display-home-map") {
        var names = [
          ["melbourne", -37.814532, 144.960504, 197, 43],
          ["wollert", -37.596806, 145.033169, 99, 28],
          ["truganina", -37.814358, 144.719648, 100, 28],
          ["newgate", -37.846133, 144.64983, 100, 28],
        ];
      } else {
        var names = [
          ["beveridge", -37.486227, 144.9575083, 100, 28],
          ["bundoora", -37.694965, 145.064749, 99, 28],
          ["cragieburn", -37.593996, 144.933472, 100, 28],
          ["diggers-rest", -37.627459, 144.723154, 105, 28],
          ["donnybrook", -37.551441, 144.957247, 107, 28],
          ["doreen", -37.605113, 145.145331, 100, 28],
          ["epping", -37.638222, 145.010351, 100, 29],
          ["gisborne", -37.489959, 144.58978, 99, 28],
          ["greenvale", -37.632971, 144.881874, 99, 29],
          ["humevale", -37.497322, 145.174271, 100, 28],
          ["kalkallo", -37.525764, 144.947286, 100, 28],
          ["kilmore", -37.302303, 144.951448, 100, 28],
          ["kinglake", -37.519332, 145.356779, 99, 29],
          ["lalor", -37.667341, 145.019035, 62, 29],
          ["lancefield", -37.276829, 144.729868, 100, 28],
          ["melbourne-airport", -37.669021, 144.841038, 74, 33],
          ["melbourne", -37.814532, 144.960504, 197, 43],
          ["mernda", -37.589378, 145.103694, 100, 28],
          ["mickleham", -37.545938, 144.854047, 100, 28],
          ["millpark", -37.671098, 145.0618, 100, 28],
          ["romsey", -37.350542, 144.743555, 100, 28],
          ["south-morang", -37.633298, 145.083231, 116, 29],
          ["sunbury", -37.581401, 144.713354, 100, 28],
          ["thomastown", -37.681954, 145.013575, 111, 29],
          ["wallan", -37.408233, 144.964256, 100, 28],
          ["whittlesea", -37.513526, 145.114229, 100, 28],
          ["wollert", -37.596806, 145.033169, 99, 28],
          ["wondong", -37.33505, 145.03586, 100, 29],
          ["woodstock", -37.544003, 145.056109, 100, 29],
          ["caroline-springs", -37.744808, 144.74008, 129, 28],
          ["hoppers-crossing", -37.869128, 144.69264, 139, 31],
          ["melton", -37.677438, 144.611555, 100, 30],
          ["plumpton", -37.688722, 144.667681, 100, 29],
          ["point-cook", -37.918015, 144.747692, 100, 28],
          ["rockbank", -37.733276, 144.666587, 100, 29],
          ["tarneit", -37.80919, 144.667413, 100, 28],
          ["truganina", -37.814358, 144.719648, 100, 28],
          ["werribee", -37.900483, 144.663716, 100, 30],
          ["wyndham-vale", -37.8589179, 144.5333979, 120, 29],
          ["newgate", -37.846133, 144.64983, 100, 28],
        ];
      }

      mapMarker(baseUrl, names, markerScale);

      google.maps.event.addListener(houseLandMap, "zoom_changed", function () {
        var zoom = houseLandMap.getZoom();
        var scale = 1;
        if (zoom <= 9) {
          scale = 0.55;
        } else if (zoom <= 10) {
          scale = 0.7;
        } else if (zoom <= 11) {
          scale = 0.85;
        } else {
          scale = 1;
        }
        // switch (zoom) {
        //   case 10:
        //     scale = 0.7;
        //     break;
        //   case 11:
        //     scale = 0.85;
        //     break;
        //   default:
        //     scale = 1;
        // }
        clearMarker();
        mapMarker(baseUrl, names, scale);
      });

      function mapMarker(baseUrl, names, scale) {
        for (var i = 0; i < names.length; i++) {
          var name = names[i];
          var image = {
            url: baseUrl + name[0] + ".png",
            size: new google.maps.Size(name[3], name[4]),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(name[3] / 2, name[4] / 2),
            scaledSize: new google.maps.Size(name[3] * scale, name[4] * scale),
          };
          markers[i] = new google.maps.Marker({
            position: { lat: name[1], lng: name[2] },
            icon: image,
            map: houseLandMap,
          });
        }
      }

      function clearMarker() {
        for (var i = 0; i < markers.length; i++) {
          markers[i].setMap(null);
        }
      }
    }

    if ($("#house-land-map").length > 0) {
      initHouseLandMap("house-land-map");
    }

    // Display home map
    // var displayHomeMap;
    // var displayHomeMarkers = [];

    // function initDisplayHomeMap() {
    //   displayHomeMap = new google.maps.Map(
    //     document.getElementById("display-home-map"),
    //     {
    //       zoom: 11,
    //       fullscreenControl: false,
    //       mapTypeControl: false,
    //       scrollwheel: false,
    //       center: { lat: -37.679414, lng: 144.960965 }
    //     }
    //   );
    // }

    if ($("#display-home-map").length > 0) {
      initHouseLandMap("display-home-map");
    }

    $(".menu-navigation a, .footer-link-container a").click(function (e) {
      var currentLocation = window.location;
      if ($(this).attr("href")) {
        var url = $(this).attr("href").split("#");
        if (
          typeof url[1] !== "undefined" &&
          url[0] == currentLocation.pathname
        ) {
          e.preventDefault();
          $(
            ".header-navigation, .navigation, .main, html, body, .header-menu-container"
          ).removeClass("menu-open");
          $("#hamburger").removeClass("active");
          $("#header-menu").fadeOut("fast", function () {
            $("#header .navigation a.header-menu").each(function () {
              $(this).removeClass("hover-active");
            });
          });
          scrollTo(url[1]);
        }
      }
    });

    if (location.hash) {
      window.scrollTo(0, 0);
      setTimeout(function () {
        window.scrollTo(0, 0);
        $(document)
          .imagesLoaded()
          .done(function () {
            scrollTo(location.hash);
          });
      }, 1);
    }

    function scrollTo(target) {
      var $targetElement = $("#" + target.replace("#", ""));
      if ($targetElement.length) {
        $("body,html").animate(
          {
            scrollTop: $targetElement.offset().top - headerHeight,
          },
          1000
        );
      }
    }

    $(".customers .customer").click(function () {
      $(".customers .customer").removeClass("active");
      $(this).addClass("active");
    });

    $("#enquiry .btn-close").click(function () {
      closeEnquireForm();
    });

    $("#enquiry-floorplan .btn-close").click(function () {
      closeEnquireFloorplanForm();
    });

    $("#investor-form .btn-close").click(function () {
      closeInvestorForm();
    });

    $(".btn-enquire").click(function () {
      $("html, body").css("overflow", "hidden");
      $("#enquiry").show();
      $("#enquiry .enquiry-content").scrollTop(0);
      $("#enquiry .enquiry-form-container").addClass("active");
      $("#enquiry .enquiry-form-container")
        .find(".house-name")
        .html($(this).data("name"));
      $("#enquiry .enquiry-form-container")
        .find(".house-and-land")
        .val($(this).data("name"));
        $("#enquiry .enquiry-form-container")
          .find(".house-and-land-consultant-name")
          .val($(this).data("consultant-name"));
          $("#enquiry .enquiry-form-container")
            .find(".house-and-land-consultant-email")
            .val($(this).data("consultant-email"));
            $("#enquiry .enquiry-form-container")
            .find(".house-and-land-lot-address")
            .val($(this).data("lot-address"));
    });

    $(".btn-enquire-floorplan").click(function () {
      $("html, body").css("overflow", "hidden");
      $("#enquiry-floorplan").show();
      $("#enquiry-floorplan .enquiry-content").scrollTop(0);
      $("#enquiry-floorplan .enquiry-form-container").addClass("active");
      $("#enquiry-floorplan .enquiry-form-container")
        .find(".house-name")
        .html($(this).data("name"));
      $("#enquiry-floorplan .enquiry-form-container")
        .find(".floorplan")
        .val($(this).data("name"));
    });

    $(".btn-investor").click(function () {
      $("html, body").css("overflow", "hidden");
      $("#investor-form").show();
      $("#investor-form .investor-content").scrollTop(0);
      $("#investor-form .investor-form-container").addClass("active");
    });

    $(document).keyup(function (e) {
      if (e.keyCode === 27) {
        closeEnquireForm();
        closeEnquireFloorplanForm();
        closeInvestorForm();
      }
    });

    function closeEnquireForm() {
      $("#enquiry .enquiry-form-container").removeClass("active");
      setTimeout(function () {
        $("html, body").css("overflow", "auto");
        $("#enquiry").hide();
      }, 300);
    }

    function closeEnquireFloorplanForm() {
      $("#enquiry-floorplan .enquiry-form-container").removeClass("active");
      setTimeout(function () {
        $("html, body").css("overflow", "auto");
        $("#enquiry-floorplan").hide();
      }, 300);
    }

    function closeInvestorForm() {
      $("#investor-form .investor-form-container").removeClass("active");
      setTimeout(function () {
        $("html, body").css("overflow", "auto");
        $("#investor-form").hide();
      }, 300);
    }

    $(".popup-image").magnificPopup({
      type: "image",
      mainClass: "mfp-img-white",
      image: {
        verticalFit: true,
      },
    });

    $(".popup-youtube").magnificPopup({
      disableOn: 700,
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,

      fixedContentPos: false,
    });

    $(".privacy, .disclaimer").magnificPopup({
      type: "ajax",
    });

    function positionHotSpots(hotspot) {
      var parent = hotspot.closest(".inclusion"),
        image = parent.find("img"),
        imageNaturalWidth = image.get(0).naturalWidth,
        imageWidth = image.width(),
        resizeRatio = imageWidth / imageNaturalWidth;

      var xPos = hotspot.data("x") * resizeRatio;
      var yPos = hotspot.data("y") * resizeRatio;
      hotspot.css({
        "margin-top": yPos + "px",
        "margin-left": xPos + "px",
      });
    }

    // if ($("#blog-page").length) {
    //   var blogUrl = "https://astonhomes.com.au/blog/";
    //   $.get(blogUrl, function (data) {
    //     $("#blog-page").html($(data).find("#blog-page .container"));
    //   });
    // }

    $("#form-floor-plans .land-width").ionRangeSlider({
      grid: true,
      values: ["8", "9", "10", "11", "12", "13", "14", "15", "16", "16+"],
    });

    $("#form-floor-plans .land-depth").ionRangeSlider({
      grid: true,
      values: [
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "32+",
      ],
    });

    $("#form-floor-plans .type-all").click(function () {
      $("#form-floor-plans .floor-plan-type").val("all");
    });

    $("#form-floor-plans .type-everyday").click(function () {
      $("#form-floor-plans .floor-plan-type").val("everyday");
    });

    $("#form-floor-plans .type-essential").click(function () {
      $("#form-floor-plans .floor-plan-type").val("essential");
    });

    if ($("#form-floor-plans").length && location.search) {
      var parsed = queryString.parse(location.search);
      $.each(parsed, function (key, value) {
        if (key == "width") {
          $("#form-floor-plans .land-width").val(value);
          var instance = $("#form-floor-plans .land-width").data(
            "ionRangeSlider"
          );
          var index = 0;
          if (value == "16+") {
            index = 9;
          } else {
            index = value - 8;
          }
          instance.update({
            from: index,
          });
        } else if (key == "depth") {
          $("#form-floor-plans .land-depth").val(value);
          var instance = $("#form-floor-plans .land-depth").data(
            "ionRangeSlider"
          );
          console.log(value);
          var index = 0;
          if (value == "32+") {
            index = 11;
          } else {
            index = value - 20;
          }
          console.log(index);
          instance.update({
            from: index,
          });
        } else {
          $("#form-floor-plans")
            .find("input[name=" + key + "]")
            .each(function () {
              if ($(this).val() == value) {
                $(this).attr("checked", true);
                return false;
              }
            });
        }
      });
    }

    if ($(".facades-container").length) {
      $(".facades-container").magnificPopup({
        delegate: "a",
        type: "image",
        closeOnContentClick: false,
        navBtnInside: true,
        mainClass: "mfp-facades",
        navigateByImgClick: false,
        image: {
          verticalFit: true,
          titleSrc: function (item) {
            return item.el.attr("title");
          },
        },
        gallery: {
          enabled: true,
          tPrev: "Previous",
          tNext: "Next",
        },
      });
    }

    flatpickr(".flatpickr", {
      dateFormat: "j M Y",
    });

    if ($(".sticker-ad").length) {
      // sessionStorage.setItem("astonStickerClosed", 0);
      var astonStickerClosed = sessionStorage.getItem("astonStickerClosed");
      var ignoreResize = false;
      if (astonStickerClosed != 1) {
        $(".sticker-ad").show();
        var stickerHeight = $(".sticker-ad").height();
        var adminBarHeight = $("#wpadminbar").length
          ? $("#wpadminbar").height()
          : 0;
        var headerHeight = $("#header").height();
        $(".main").css({
          "padding-top": stickerHeight + "px",
        });
        if ($(window).width() <= 1080) {
          $("#header-menu").css({
            top: stickerHeight + "px",
          });
        } else {
          $("#header-menu").css({
            top: stickerHeight + adminBarHeight + headerHeight + "px",
          });
        }
        $("#header").css({
          top: stickerHeight + "px",
        });

        $(window).resize(function () {
          if (ignoreResize == false) {
            var stickerHeight = $(".sticker-ad").height();
            var adminBarHeight = $("#wpadminbar").length
              ? $("#wpadminbar").height()
              : 0;
            var headerHeight = $("#header").height();
            $(".main").css({
              "padding-top": stickerHeight + "px",
            });
            if ($(window).width() <= 1080) {
              $("#header-menu").css({
                top: stickerHeight + "px",
              });
            } else {
              $("#header-menu").css({
                top: stickerHeight + adminBarHeight + headerHeight + "px",
              });
            }
            $("#header").css({
              top: stickerHeight + "px",
            });
          }
        });
      }

      $(".sticker-ad .btn-close").click(function () {
        ignoreResize = true;
        sessionStorage.setItem("astonStickerClosed", 1);
        $(".sticker-ad").hide();
        $(".main").css({
          "padding-top": "",
        });
        $("#header-menu").css({
          top: "",
        });
        $("#header").css({
          top: "",
        });
      });
    }
  });

  $(document).ready(function($){
    $( ".lot-width, .lot-depth, .range, .garages, .living, .bedrooms" ).on( "change", function() {
      var lotwidth = $( '.lot-width' ).val();
      var lotdepth = $( '.lot-depth' ).val()
      var range    = $( '.range' ).val()
      var living   = $( '.living' ).val()
      var garages  = $( '.garages' ).val()
      var bedrooms = $( '.bedrooms' ).val()
  
      var data = {
        'action'  : 'filterposts',
        'lotwidth': lotwidth,
        'lotdepth': lotdepth,
        'range'   : range,
        'living'  : living,
        'garages' : garages,
        'bedrooms': bedrooms
      };
  
      // if(sort == "asc"){
      //   $("#sort").val("desc");
      // }
      // else{
      //   $("#sort").val("asc");
      // }
      $.ajax({
        url : ajaxurl,
        data : data,
        type : 'POST',
        beforeSend : function ( xhr ) {
          $('.filtered-posts').html( '<div class="aston-loader"><img  src="/wp-content/themes/aston/assets/img/aston-loader.gif"></div>' );
          $('.js-category').attr( 'disabled', 'disabled' );
          $('.js-date').attr( 'disabled', 'disabled' );
        },
        
        success : function( data ) {
          if ( data ) {
            $('.filtered-posts').html( data.posts );
				      $(".floor-plan-facades .swiper-container").each(function () {
              var swiperFacade = new Swiper($(this)[0], {
                speed: 600,
              });
              $(this)
                .closest(".floor-plan-facades-container")
                .find(".btn-next")
                .click(function () {
                swiperFacade.slideNext();
                });
              $(this)
                .closest(".floor-plan-facades-container")
                .find(".btn-prev")
                .click(function () {
                swiperFacade.slidePrev();
                });
              });
              jquery__WEBPACK_IMPORTED_MODULE_0___default()(".popup-image").magnificPopup({
                type: "image",
                mainClass: "mfp-img-white",
                image: {
                  verticalFit: true
                }
              });
              $(".btn-enquire-floorplan").click(function () {
                $("html, body").css("overflow", "hidden");
                $("#enquiry-floorplan").show();
                $("#enquiry-floorplan .enquiry-content").scrollTop(0);
                $("#enquiry-floorplan .enquiry-form-container").addClass("active");
                $("#enquiry-floorplan .enquiry-form-container")
                  .find(".house-name")
                  .html($(this).data("name"));
                $("#enquiry-floorplan .enquiry-form-container")
                  .find(".floorplan")
                  .val($(this).data("name"));
              });
            $('.js-category').removeAttr('disabled');
            $('.js-date').removeAttr('disabled');
          } else {
            $('.filtered-posts').html( '<h2 class="no-filter">There are no home designs currently matching your search criteria.Please refine your search and try again. </h2>' );
          }
        }
       
      });
    });
  });
  